module.exports = {
  pathPrefix: '/', // Prefix for all links. If you deploy your site to example.com/portfolio your pathPrefix should be "/portfolio"

  siteTitle: 'Raymond Ware // FullStack Freelance Web Developer', // Navigation and Site Title
  siteTitleAlt: 'Raymond Ware', // Alternative Site title for SEO
  siteTitleShort: 'Raymond ware', // short_name for manifest
  siteHeadline: 'Fullstack JavaScript Developer', // Headline for schema.org JSONLD
  siteUrl: 'https://www.raymondware.com/', // Domain of your site. No trailing slash!
  siteLanguage: 'en', // Language Tag on <html> element
  siteLogo: '/logo.png', // Used for SEO and manifest
  siteDescription: '',
  author: 'Raymond Ware', // Author for schema.org JSONLD

  // siteFBAppID: '123456789', // Facebook App ID - Optional
  userTwitter: '@raymondware', // Twitter Username
  ogSiteName: 'raymondware', // Facebook Site Name
  ogLanguage: 'en_US', // Facebook Language
}
