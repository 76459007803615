import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  *, *:before, *:after {
    box-sizing: inherit;
  }
  html {
    text-rendering: optimizeLegibility;
    overflow-x: hidden;
    box-sizing: border-box;
    -ms-overflow-style: scrollbar;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    background-color: #CECFDA;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  html, body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    font-family: 'Source Sans Pro', Arial, sans-serif;
    font-size: 16px;
  }
  a {
    color: #34424a;
    text-decoration: none;
  }
  p {
    font-size: 1.125em;
    line-height: 1.7em;
    margin: 10px 0;
  }
  h1, h2, h3, h4, h5, h6 {
    font-family: 'Ubuntu', Helvetica, Arial, sans-serif;
    margin: 25px 0 10px;
  }
  .mobileMenuButton {
    position: absolute;
    top: 55px;
    right: 45px;
    font-size: 2em;
    z-index: 9999;
    cursor: pointer;
  }

  /* Gallery modal */
  .fullscreen {
      position: absolute;
      z-index: 9999;
  }

  /* Code blocks */
  pre[class*="language-"] {
    padding: 1em;
    margin: .5em 0;
    overflow: auto;
    border-radius: 7px;
    border: 1px solid #000;
    box-shadow: inset 4px 4px 25px 0 rgba(0,0,0,.36);
  }

  .google-auto-placed {
    overflow: hidden;
  }
`

export default GlobalStyle
