import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'

const NavBarWrap = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 10px 55px;
  position: relative;
  z-index: 4444;
  
  @media screen and (max-width: 960px) {
    position: relative;
    margin-bottom: 45px;
    padding: 10px 15px;
  }
`

const Logo = styled.div`
  width: 77px;

  img {
    width: 100%;
    max-width: 77px;

    @media screen and (max-width: 960px) {
      max-width: 60px;
    }
  }
`

const NavLinksContainer = styled.div`
  width: 80%;
  color: #fff;
  font-size: 1em;
  font-weight: 700;

  ul {
    list-style-type: none;
    text-align: right;

    li {
      display: inline-block;
      cursor: pointer;
      transition: all .5s;
      width: 95px;
      border-radius: 25px;
      padding: 5px;
      border: 2px solid white;
      text-align: center;
      cursor: pointer;
      font-size: 1em;
      font-weight: 700;
      background: linear-gradient(to top right,#1a6bdd 0%,#45a7f7 100%);
      border: none;
      color: #fff;

      a {
        color: #fff;
      }

      &:last-child {
        margin-right: 0;
      }

      &:hover {
        box-shadow: 0 14px 28px rgba(0,0,0,0.15), 0 10px 10px rgba(0,0,0,0.12);
      }
    }
  }
`

const NavBar = () => (
  <>
    <NavBarWrap id="navigation-wrapper">
      <Logo>
        <Link to="/">
          <img src="/images/raymond-ware-logo.svg" alt="Raymond Ware Logo"/>
        </Link>
      </Logo>
      <NavLinksContainer>
        <ul>
          { typeof window !== 'undefined' && window.location.pathname !== '/blog/' && window.location.pathname !== '/blog' ? <Link to="/blog"><li>Blog</li></Link> : '' }
        </ul>
      </NavLinksContainer>
    </NavBarWrap>
  </>
)

export default NavBar