import React from 'react'
import GlobalStyle from '../styles/global'
import SEO from '../components/SEO'

const Layout = props => (
  <>
    <SEO {...props} />
    <GlobalStyle />
  </>
)

export default Layout
