import React from 'react'
import styled from 'styled-components'

const FooterContainer = styled.footer`
    background: linear-gradient(125deg, #000, #0f0f0f);
    padding: 25px;
    color: #37515d;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: 850px) {
        display: block;
        text-align: center;
    }
`

const Footer = () => (
    <FooterContainer>
        <div role="presentation">
            <img src="/images/footer-logo.png" alt="Raymond Ware logo" />
        </div>
        <div>
            ©2020 Made from scratch by Raymond Ware
        </div>
    </FooterContainer>
)

export default Footer
