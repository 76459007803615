import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const OverlayContainer = styled.div`
    width: 100%;
    height: 100%;
    margin: 0 auto;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2222;
    background: ${props => props.bg || '#333'};
    opacity: ${props => props.opacityVal || '.7'};
    display: ${props => props.display || 'none'};

    @media screen and (max-width: ${props => props.mediaDisplay || '960px'}) {
        display: block;
    }
`

const Overlay = ({bg, opacityVal, display, mediaDisplay}) => (<>
    <OverlayContainer bg={bg} opacityVal={opacityVal} display={display} mediaDisplay={mediaDisplay} />
</>)

Overlay.propTypes = {
    bg: PropTypes.string,
    opacityVal: PropTypes.string,
    display: PropTypes.string,
    mediaDisplay: PropTypes.string
}
  
Overlay.defaultProps = {
    bg: '#333',
    opacityVal: '.7',
    display: 'none',
    mediaDisplay: '960px'
}

export default Overlay
